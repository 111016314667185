import React from 'react'
import { useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import ReactMarkdown from 'react-markdown';

function ArticlePage({ isAuth, signUserOut }) {
    const { id } = useParams()
    
    const { loading, error, data } = useFetch(`${process.env.REACT_APP_FETCH_DATA}/api/articles/${id}?populate=thumbnail`)

    if (loading) return <p className='loading-sign'>Loading...</p>
    if (error) return <p className='error-sign'>Error :(</p>

    const article = data.attributes;
    const imageUrl = `${article.thumbnail.data.attributes.url}`
    
    return (
        <>
          <div className="article-container">
              <div className="heading">{article.heading}</div>
              <div className="article-details">
                  <div className="author">{article.author}</div>
                  <div className="date">{article.date}</div>
              </div>
              <figure>
                <img className='thumbnail' src={imageUrl} alt={article.imageAlt}  width='700px'></img>
              </figure>
              <article className='content'>
                <ReactMarkdown>{article.content}</ReactMarkdown>
              </article>
              <ReactMarkdown className='sub-text'>{article.biography}</ReactMarkdown>
          </div>
        </>
  
    )
}

export default ArticlePage