import React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { Link } from "react-router-dom"
import { useNavigate } from 'react-router-dom';
import Header from './Header';

import useFetch from '../hooks/useFetch';

const MainSection = () => {
    const navigate = useNavigate()
    const { loading, error, data } = useFetch(`${process.env.REACT_APP_FETCH_DATA}/api/articles?populate=thumbnail&sort=createdAt:desc`)

    const handleClick = (e, articleId) => {
        e.preventDefault()
        navigate(`/article-page/${articleId}`)
    }

    if (loading) return <p className='loading-sign'>Loading...</p>
    if (error) return <p className='error-sign'>Error :(</p>

  return (
    <>
        <Header />
        <section className="main-section">
            <div className="container"> 
                {
                    
                    data.map( obj => {
                        const articleId = obj.id;
                        const article = obj.attributes;
                        const imageUrl = `${article.thumbnail?.data?.attributes?.url}`
                        // const imageUrl = `http://massage-miami-central.com/wp-content/uploads/2022/05/Downloader.la-62262c8bada1e-1-768x512-2.jpg`
                        console.log(article)
                        return (
                            <div key={articleId} className='featured-article' style={{backgroundImage: `url(${imageUrl})`}}>
                                <div className="heading">
                                    <h4 className='title'>{article.heading}</h4>
                                    <p className='author'>{article.author}</p>
                                </div>
                                <button><Link to='article-page' onClick={(e) => handleClick(e, articleId)}>Read <FaArrowRight /></Link>
                                </button>
                            </div>  
                        )
                    })
                }
            </div>
        </section>
    </>

  )
}

export default MainSection