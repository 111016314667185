import React from 'react'
import { Link } from 'react-router-dom'
import navLinks from '../navLinks'

function Footer() {
    const links = navLinks()
    
  return (
    <div className='footer'>
        <div className="top-half">
            <div className="container">
                <h5>KiddushRoom</h5>
                <ul className="footer-links-row-1">
                    { 
                        links.map(link => <li key={link.name} className="nav-li"><Link to={link.path}>{link.name}</Link></li>)
                    }
                </ul>
            </div>
        </div>
        <div className="bottom-half">
            <p>&copy; Copyright 2022, KiddushRoom</p>
            <p>Design + Developent by <a href="https://shalomkohn.com">Shalom Kohn</a></p>
        </div>
    </div>
  )
}

export default Footer