function navLinks() {
    const navLinks = [
        {
          name: 'Home',
          path: '/'
        },
        {
          name: 'Wellness',
          path: '/single-category-page/wellness'
        },
        {
          name: 'Travel',
          path: '/single-category-page/travel'
        },
        {
          name: 'Food & Tradition',
          path: '/single-category-page/food%20&%20tradition'
        },
        {
          name: 'Recipe',
          path: '/single-category-page/recipe'
        }
      ]
    return navLinks
}

export default navLinks