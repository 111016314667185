import './App.css';
import MainSection from './components/MainSection';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ArticlePage from './pages/ArticlePage';
import AuthorPage from './pages/AuthorPage';
import SingleCategoryPage from './pages/SingleCategoryPage'
import Nav from './components/Nav'
import ScrollToTop from "./components/ScrollToTop"


function App() {

  return (
    <Router>
      <ScrollToTop />
      <>
        <Nav />
        <Routes>
          <Route path="/" element={<MainSection />} />
          <Route path="/article-page/:id" element={<ArticlePage />}/>
          <Route path="/single-category-page/:category/" element={<SingleCategoryPage />} />
          <Route path="/author-page/" element={<AuthorPage />} />
        </Routes>
        <Footer />
      </>
    </Router>

  );
}

export default App;
